<template>
  <y_layout>

    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <el-page-header @back="$router.back()" content="商家核销员列表"></el-page-header>
      <div style="padding: 1rem;" class="flex-def flex-zEnd">
        <y_choose_member @change="uidChoose" label="添加核销员" :box="true" v-model="form.uid"></y_choose_member>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="member.id" label="用户编号"></el-table-column>
        <el-table-column label="头像">
          <template #default="s">
            <el-avatar v-if="s.row.member" shape="square" size="small" :src="s.row.member.avatar | tomedia"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="member.nickname" label="昵称"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template #default="scope">
            <el-button @click="del(scope.row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import y_choose_member from "@/components/y_choose_member";
export default {
  name: "clerk",
  components:{
    y_layout,
    y_choose_member,
  },
  data(){
    return{
      form:{
        uid:0
      },
      list:[],
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    uidChoose(e){
      this.$api.clerk.add({
        merchant_id:parseInt(this.$route.query.merchant_id),
        uid:e
      }).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    del(id){
      this.$api.clerk.del({
        clerk_id:id,
        merchant_id:parseInt(this.$route.query.merchant_id),
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.clerk.all({
        merchant_id:parseInt(this.$route.query.merchant_id)
      }).then(res=>{
        this.list = res;
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>