<template>
  <div>
    <el-input v-if="!box" :value="showvalue" disabled>
      <template #append>
        <el-button @click="dialog = true">{{ label }}</el-button>
      </template>
    </el-input>
    <el-button v-else @click="dialog = true">{{ label }}</el-button>
    <el-dialog :title="label" :visible.sync="dialog" append-to-body	>
      <div class="flex-def flex-zCenter">
        <el-form :model="searchForm" inline>
          <el-form-item label="用户昵称">
            <el-input v-model="searchForm.nickname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchGo" style="margin-left: 1rem" type="primary">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list">
        <el-table-column property="id" label="编号" width="60"></el-table-column>
        <el-table-column label="头像" width="80">
          <template #default="scope">
            <el-avatar shape="square" size="small" :src="scope.row.avatar | tomedia"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column property="nickname" label="昵称"></el-table-column>
        <el-table-column label="操作" width="80">
          <template #default="s">
            <el-button @click="chooseUser(s.row)" size="mini">添加</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>

export default {
  name: "y_choose_member",
  components:{

  },
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    box:{
      type:Boolean,
      default: false
    },
    label:{
      type:String,
      default:"选择粉丝"
    },
    modelval: {
      type:Number,
      default:0
    }
  },
  data(){
    return{
      value: this.modelval,
      showvalue:"",
      dialog:false,
      searchForm:{
        nickname:"",
      },
      list:[],
      page:1,
      size:15,
      total:0
    }
  },
  mounted() {
    this.loadValue();
  },
  watch:{
    modelval(){
      this.loadValue();
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    },
  },
  methods:{
    loadValue(){
      if(this.modelval === 0){
        return;
      }
      this.$api.member.one({id:this.modelval}).then(res=>{
        this.showvalue = "#"+res.id+":"+res.nickname;
      });
    },
    pageChange(e){
      this.page = e;
      this.searchGo();
    },
    searchGo(){
      this.$api.member.search({
        nickname: this.searchForm.nickname,
        page:this.page
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
    close(){
      this.searchForm = {
        nickname: ""
      };
      this.list = [];
      this.dialog = false;
    },
    chooseUser(item){
      this.showvalue = "#"+item.id+":"+item.nickname
      this.value = item.id;
      this.close();
    }
  }
}
</script>

<style scoped>

</style>